import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceLang {
    lookupService = new APIServiceLookUp();

    async setLang(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lang/setLang`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateLang(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lang/updateLang`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllLang(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lang/getAllLang`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getLangOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lang/getLangOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delLang(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lang/delLang`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}