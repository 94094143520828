import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom, paramAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import moment from 'moment';
import { toast } from 'react-toastify';
import { Device } from 'mediasoup-client';
import { motion } from "framer-motion";

import ServiceTicket from '../../services/serviceTickets';

import TopBar from '../../core/widgets/ui/TopBar';

import socket from '../../core/config/socket';

export default function PanelTicketLive() {

    const [userData] = useRecoilState(userAtom);
    const [token] = useRecoilState(tokenAtom);
    const [param, setParam] = useRecoilState(paramAtom);

    const [mDevice, setMDevice] = useState(null);
    const [conTransport, setConsumerTransport] = useState(null);

    const [crdAudio, setCrdAudio] = useState(null);
    const [crdVideo, setCrdVideo] = useState(null);

    const [chats, setChats] = useState([]);

    const [ticket, setTicket] = useState({});

    const [roomid, setRoomID] = useState('');

    const remoteVideoRef = useRef();

    const navigate = useNavigate();
    const ticketService = new ServiceTicket();

    const initMediaSoup = async (rtpCapabilities) => {
        try {
            let dev = new Device();
            await dev.load({
                routerRtpCapabilities: rtpCapabilities
            });
            setMDevice(dev);
        } catch (error) {
            console.log(error);
            if (error.name === 'UnsupportedError') {
                console.warn('browser not supported');
            }
        }
    }

    const connectToMeet = () => {
        initTransports(roomid);
    }

    const initTransports = async (rmid) => {
        // init consumerTransport
        {
            socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsB) => {
                if (paramsB.error) {
                    console.error(paramsB.error)
                    return
                }

                let consumerTransport = mDevice.createRecvTransport(paramsB);
                consumerTransport.on('connect', function ({ dtlsParameters }, callback, errback) {
                    socket.emit('connectTransport', { roomid: rmid, transport_id: consumerTransport.id, dtlsParameters }, (res) => {
                        console.log(res);
                    });
                    callback();
                }.bind(this));

                consumerTransport.on('connectionstatechange', async function (state) {
                    console.log(state);
                    switch (state) {
                        case 'failed':
                            consumerTransport.close();
                            break;
                        default:
                            break;
                    }
                }.bind(this));

                consumerTransport.on('failed', function (error) {
                    console.error('Consumer transport failed:', error);
                }.bind(this));

                setConsumerTransport(consumerTransport);

                socket.emit('getProducers', { roomid: rmid });
            });
        }
    }

    const getConsumeStream = async (producerId) => {
        const { rtpCapabilities } = mDevice;
        socket.emit('consume-agent', { roomid, rtpCapabilities, consumerTransportId: conTransport.id, producerId }, async (param) => {
            const { id, kind, rtpParameters } = param;
            let codecOptions = {}
            const consumer = await conTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions
            });

            if (remoteVideoRef.current.srcObject === null) {
                const stream = new MediaStream();
                stream.addTrack(consumer.track);
                remoteVideoRef.current.srcObject = stream;
            } else {
                remoteVideoRef.current.srcObject.addTrack(consumer.track);
            }

            if (kind === 'audio') {
                consumer.on('trackended', function () {
                    setCrdAudio(null);
                }.bind(this));

                consumer.on('transportclose', function () {
                    setCrdAudio(null);
                }.bind(this));

                setCrdAudio(consumer);
            } else {
                consumer.on('trackended', function () {
                    setCrdVideo(null);
                }.bind(this));

                consumer.on('transportclose', function () {
                    setCrdVideo(null);
                }.bind(this));

                setCrdVideo(consumer);
            }
        });
    }

    const gotoBack = () => {
        setParam({});
        navigate(`/home/ticket`);
    }

    const loadData = () => {
        (async function () {
            let body = {
                "tid": param.eid
            };
            var res = await ticketService.getTicketById(body, token);
            if (res['status']) {
                setTicket(res.data);
                setRoomID(res.data.ticketId);
                socket.emit('adminJoin', { roomid: res.data.ticketId, iid: userData._id, name: `Admin - ${userData.user.fname}` }, (rtpCapabilities) => {
                    initMediaSoup(rtpCapabilities);
                });
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        if (roomid === '') {
            loadData();
        }
    }, [roomid]);

    useEffect(() => {
        if (mDevice !== null && roomid !== '') {
            connectToMeet();
        }
    }, [mDevice, roomid]);

    useEffect(() => {
        if (conTransport !== null && mDevice !== null) {
            socket.on('newProducers', async function (data) {
                if (data.length !== 0) {
                    for (let { producer_id } of data) {
                        console.log(producer_id);
                        await getConsumeStream(producer_id);
                    }
                }
            }.bind(this));
        }
    }, [conTransport, mDevice]);

    useEffect(() => {
        socket.on('recvChat', (chat) => {
            let chatList = [...chats];
            chatList.push(chat);
            setChats(chatList);
        });
    }, [chats]);

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { gotoBack() }}></motion.i> Ticket | <span className='uppercase'>{ticket.ticketId}</span>
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                    </div>
                    <div className="col-span-12 mt-2 h-screen">
                        <div className='w-full h-full px-2'>
                            <div className='h-full w-full grid grid-cols-12 gap-4 my-2 md:my-4'>
                                <div className={`col-span-8 h-full bg-gray-50 rounded-lg flex flex-col`}>
                                    <div className='w-full h-[90%] border-2 border-prime rounded-md'>
                                        <div className='w-full h-full relative'>
                                            <video ref={remoteVideoRef} autoPlay className='rounded-md object-fill absolute inset-0 shadow w-full h-full' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-4 bg-gray-50 rounded-lg flex flex-col shadow-md p-2'>
                                    <div className='p-2'>
                                        <span className='text-lg font-medium'>Chats</span>
                                    </div>
                                    <div className='h-[1px] bg-gray-900' ></div>
                                    <ScrollArea className="w-full min-h-[60vh] flex-grow bg-gray-100 my-2 p-2 rounded-md">
                                        {
                                            chats.map((chat) => {
                                                return <div className='w-full mb-4'>
                                                    <div className='w-full mb-2 flex'>
                                                        <div className='flex-grow'>
                                                            <h1 className='text-sm font-medium'>{chat.name}</h1>
                                                            <p className='text-xs text-gray-600 mt-1'>{chat.message}</p>
                                                        </div>
                                                        <div>
                                                            <p className='text-xs text-gray-400'>{moment(chat.time).format('hh:mm')}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            })
                                        }
                                    </ScrollArea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
