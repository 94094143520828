import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAuth {
    lookupService = new APIServiceLookUp();

    async staffRegister(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/staffRegister`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async interpreterRegister(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/interpreterRegister`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async partnerRegister(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/partnerRegister`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async userLogin(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/staffLogin`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async IDPUserUpdate(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/IDPUserUpdate`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async IDPUserPasswordUpdate(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/IDPUserPasswordUpdate`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async IDPUserReset(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/IDPUserReset`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}