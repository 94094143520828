import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { toast } from 'react-toastify';

import ServiceProfiles from 'services/serviceProfiles';
import ServiceUtility from 'services/serviceUtility';

import ComponentPartnerInfo from './component/ComponentPartnerInfo';

export default function PanelPartnerInfo(props) {

    const { id } = useParams();

    const [token] = useRecoilState(tokenAtom);

    const [partners, setPartners] = useState([]);

    const [selID, setSelID] = useState(id);
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const navigate = useNavigate();
    const profileService = new ServiceProfiles();
    const utilityService = new ServiceUtility();

    const gotoPartner = () => {
        navigate(`/home/profileP`);
    }

    const gotoNewPartner = (id) => {
        navigate(`/home/partnerInfo/${id}`);
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await profileService.getAllPartner(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setPartners(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": {},
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await profileService.getAllPartner(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setPartners(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query]);

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <ScrollArea className="w-[320px] h-screen bg-slate-50 border-r-2">
                <div className='w-full'>
                    <div className='flex items-center justify-between px-2 pt-4'>
                        <div className='h-[28px] w-[28px] rounded-md flex items-center justify-center cursor-pointer hover:bg-gray-200' onClick={() => {
                            gotoPartner();
                        }}>
                            <i className="las la-arrow-left text-lg"></i>
                        </div>
                        <h1 className="text-xl lg:text-2xl font-medium ml-2">Partners</h1>
                    </div>
                    <div className='h-[2px] bg-gray-400 mt-2'></div>
                    <ul className="w-full flex flex-col items-start">
                        {
                            partners.map((user) => {
                                return <div className={`w-full cursor-pointer ${selID === user._id ? "bg-blue-100 hover:bg-blue-50" : "bg-white hover:bg-gray-100"}`} key={user._id} onClick={() => {
                                    setSelID(user._id);
                                    gotoNewPartner(user._id);
                                }}>
                                    <div className='flex items-center px-1 py-2'>
                                        <div className='w-[48px] h-[48px] mr-2'>
                                            <img src={utilityService.getIsObjectEmpty(user.profileMedia) ? "https://storage.googleapis.com/ish-prod/ish-assets/staff.png" : user.profileMedia.fileurl} alt='Profile Image' className='w-full h-full rounded-full border-2 border-blue-600' />
                                        </div>
                                        <div>
                                            <h3 className='text-sm font-medium'>{user['companyName']}</h3>
                                            <p className='text-gray-600 text-xs'>{user.subscription.links}</p>
                                        </div>
                                    </div>
                                    <div className='h-[1px] bg-gray-400 mt-1'></div>
                                </div>
                            })
                        }
                    </ul>
                    <div className='h-[2px] bg-gray-400'></div>
                    <div className='flex py-2 px-1 items-center bg-blue-50'>
                        <select className='text-sm bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </select>
                        <div className='flex-grow'></div>
                        <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                        <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                    </div>
                </div>
            </ScrollArea>
            <ScrollArea className='w-full h-screen bg-white'>
                <ComponentPartnerInfo cid={selID} />
            </ScrollArea>
        </div>
    )
}
