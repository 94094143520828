import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";

import { motion } from "framer-motion";
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

import { useRecoilState } from 'recoil';
import { tokenAtom, paramAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceProfiles from '../../services/serviceProfiles';
import ServiceUtility from 'services/serviceUtility';

export default function PanelProfileP(props) {

    const [token] = useRecoilState(tokenAtom);
    const [, setParam] = useRecoilState(paramAtom);

    const [partners, setPartners] = useState([]);

    const [filter, setFilter] = useState({});

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);

    const navigate = useNavigate();
    const profileService = new ServiceProfiles();
    const utilityService = new ServiceUtility();

    const addProfileP = () => {
        props.onToggle();
        navigate(`/home/ProfilePAdd`);
    }

    const editProfileP = (uid) => {
        props.onToggle();
        setParam({ 'eid': uid });
        navigate(`/home/ProfilePAdd`);
    }

    const gotoPartnerInfo = (id) => {
        props.onToggle();
        navigate(`/home/partnerInfo/${id}`);
    }

    const gotoPageStyle = (uid) => {
        props.onToggle();
        setParam({ 'eid': uid });
        navigate(`/home/ProfilePStyle`);
    }

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "banned":
                setFilter({ 'status': 'banned' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const updateStatus = (id, status) => {
        let body = {
            uid: id,
            type: 'status',
            data: {
                status: status
            }
        }
        profileService.updatePartner(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    }

    const resetPassword = (id) => {
        let body = {
            uid: id
        }
        profileService.resetPartner(body, token).then((res) => {
            if (res['status']) {
                loadData();
                toast.success('Email link sent to reset the password.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        partners.forEach((item) => {
            items.push({
                "uid": item.userId,
                "fname": item.user.fname,
                "mname": item.user.mname,
                "lname": item.user.lname,
                "email": item.user.email,
                "companyName": item.companyName,
                "companyEmail": item.companyEmail,
                "companyPhone": item.companyPhone,
                "startDate": item.subscription.startDate,
                "startDate": item.subscription.startDate,
                "code": item.subscription.links,
                "status": item.status,
                "createdAt": item.createdAt
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "partners");
        XLSX.writeFile(workbook, "partners.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['uid, fname, mname, lname, email, cName, cEmail, cPhone, sdate, edate, code, status, createdAt'];
        let usersCsv = partners.reduce((acc, item) => {
            acc.push([item.userId, item.user.fname, item.user.mname, item.user.lname, item.user.email, item.companyName, item.companyEmail, item.companyPhone, item.subscription.startDate, item.subscription.startDate, item.subscription.links, item.status, item.createdAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'partners.csv',
            fileType: 'text/csv',
        });
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await profileService.getAllPartner(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setPartners(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? sdate.toISOString() : "",
                "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await profileService.getAllPartner(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setPartners(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <ScrollArea className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2">
                            <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle() }}></motion.i> Partner Master
                        </h2>
                        <div className='flex'>
                            <div>
                                <button className="h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white mr-4 text-sm" onClick={() => { addProfileP() }}><i class="las la-plus mr-1"></i> ADD PARTNER</button>
                            </div>
                            <TopBar />
                        </div>
                    </div>
                    <div class="w-full mt-2">
                        <div className="p-2 mt-2">
                            <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'active' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('active') }}>Active</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'banned' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('banned') }}>Banned</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 mt-4 px-2">
                <div className="w-full shadow-md rounded-md border">
                    <table className="min-w-full divide-y divide-gray-200 px-4">
                        <thead className="bg-gray-50">
                            <tr className=''>
                                <th scope="col" className="border-b border-gray-200" colSpan={7}>
                                    <div className='w-full grid grid-cols-12 gap-2'>
                                        <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                            <div className='w-full flex col-span-12 items-center'>
                                                <div className='w-[80px]'>
                                                    <p>Search :</p>
                                                </div>
                                                <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by Name and Code" className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                            </div>
                                        </form>
                                        <div className='col-span-1 flex items-center'>
                                            <DropdownMenu>
                                                <DropdownMenuTrigger>
                                                    <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent>
                                                    <div
                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                        <div>
                                                            <ul className='flex flex-col items-start p-2'>
                                                                <DropdownMenuItem>
                                                                    <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                        exportToExcel();
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem>
                                                                    <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                        exportToCsv();
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Company
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Code
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Helpline number
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Contract Start Date
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Contract End Date
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Status
                                </th>
                                <th scope="col" className="px-2 py-3"></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                partners.length !== 0 && partners.map((partner) => {
                                    return <tr className="hover:bg-gray-100" key={partner._id}>
                                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => { gotoPartnerInfo(partner._id) }}>
                                            <div className="text-sm font-bold text-blue-600 uppercase flex items-center">
                                                <img src={utilityService.getIsObjectEmpty(partner.profileMedia) ? "https://storage.googleapis.com/ish-prod/ish-assets/staff.png" : partner.profileMedia.fileurl} alt='Profile Image' className='w-10 h-10 rounded mr-6 border' />
                                                {partner.companyName}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                            <div className="text-sm font-medium text-gray-900 uppercase flex items-center">
                                                {partner.subscription.links}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900 uppercase">
                                                {partner.companyPhone}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-blue-600">
                                                {Moment(partner.subscription.startDate).format('DD MMM yyyy')}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-blue-600">
                                                {Moment(partner.subscription.endDate).format('DD MMM yyyy')}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-medium rounded-full uppercase ${partner.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                {partner.status}
                                            </span>
                                        </td>
                                        <td>
                                            <DropdownMenu>
                                                <DropdownMenuTrigger>
                                                    <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent>
                                                    <div className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-60px' }}>
                                                        <div>
                                                            <ul className='flex flex-col items-start p-2'>
                                                                <DropdownMenuItem>
                                                                    <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                        resetPassword(partner.userId);
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <i className="las la-lock text-lg"></i>
                                                                            <p className='text-sm font-serif ml-2'>Reset password</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem>
                                                                    <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                        editProfileP(partner._id);
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <i className="las la-edit text-lg"></i>
                                                                            <p className='text-sm font-serif ml-2'>Edit Profile</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem>
                                                                    <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                        gotoPageStyle(partner._id);
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <i className="las la-edit text-lg"></i>
                                                                            <p className='text-sm font-serif ml-2'>Page Customization</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                                {
                                                                    partner.status == 'active' && <DropdownMenuItem>
                                                                        <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                            updateStatus(partner._id, 'banned');
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-ban text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Make Disabled</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                }
                                                                {
                                                                    partner.status == 'banned' && <DropdownMenuItem>
                                                                        <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                            updateStatus(partner._id, 'active');
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-chevron-circle-up text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Make Active</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </td>
                                    </tr>
                                })
                            }
                            {
                                partners.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                    <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                        <div className="text-sm text-blue-600 text-center">
                                            No data found.
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div className='flex py-2 px-4 items-center bg-blue-50'>
                        <p className='text-sm font-serif'>Show rows per page</p>
                        <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </select>
                        <div className='flex-grow'></div>
                        <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                        <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                    </div>
                </div>
            </div>
        </ScrollArea >
    )
}
