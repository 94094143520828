import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, paramAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import moment from 'moment';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../../services/serviceAuth';
import ServiceProfiles from '../../services/serviceProfiles';
import ServiceUtility from '../../services/serviceUtility';

const allowedExtensions = ["png", "jpeg"];

export default function PanelProfilePAdd(props) {

    const [token] = useRecoilState(tokenAtom);
    const [param, setParam] = useRecoilState(paramAtom);

    const [ediData, setEditData] = useState({});

    const [file, setFile] = useState(null);
    const [previews, setPreviews] = useState(null);

    const [oldUrl, setOldURL] = useState('');

    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const navigate = useNavigate();
    const profileService = new ServiceProfiles();
    const utilityService = new ServiceUtility();
    const authService = new ServiceAuth();

    const formVSchema = Yup.object().shape({
        fullName: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address').required('This information is required'),
        cName: Yup.string().required('This information is required'),
        cEmail: Yup.string().email('Please enter valid email address').required('This information is required'),
        cPhone: Yup.string().required('This information is required'),
        address: Yup.string().required('This information is required'),
        city: Yup.string().required('This information is required'),
        state: Yup.string().required('This information is required'),
        country: Yup.string().required('This information is required'),
        zipcode: Yup.string().required('This information is required'),
        weburl: Yup.string().required('This information is required'),
        sdate: Yup.string().required('This information is required'),
        edate: Yup.string().required('This information is required'),
        code: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            cName: '',
            cEmail: '',
            cPhone: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            weburl: '',
            sdate: '',
            edate: '',
            code: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (isEdit) {
                setSubmit(true);
                if (file) {
                    (async function () {
                        let names = values.fullName.split(' ');
                        let body = {
                            "uid": ediData.userId,
                            "data": {
                                "fname": names[0],
                                "mname": names.length > 2 ? names[1] : "NA",
                                "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                "email": values.email
                            }
                        }
                        await authService.IDPUserUpdate(body, token);

                        var formDataA = new FormData();
                        formDataA.append("path", oldUrl);
                        formDataA.append("doc", file);

                        const resUp = await utilityService.replaceFile(formDataA, token);
                        if (resUp.status) {
                            let bodyB = {
                                'uid': ediData._id,
                                'type': 'edit',
                                'data': {
                                    "cName": values.cName,
                                    "cEmail": values.cEmail,
                                    "cPhone": values.cPhone,
                                    "sdate": values.sdate,
                                    "edate": values.edate,
                                    "code": values.code,
                                    "weburl": values.weburl,
                                    "address": {
                                        "title": "Default",
                                        "address": values.address,
                                        "city": values.city,
                                        "state": values.state,
                                        "country": values.country,
                                        "zipcode": values.zipcode,
                                    },
                                    "profileMedia": resUp.data,
                                }
                            }
                            await profileService.updatePartner(bodyB, token)
                            toast.success("Partner updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            handleReset();
                            goBack();
                        } else {
                            toast.error(resUp.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    })();
                } else {
                    (async function () {
                        let names = values.fullName.split(' ');
                        let body = {
                            "uid": ediData.userId,
                            "data": {
                                "fname": names[0],
                                "mname": names.length > 2 ? names[1] : "NA",
                                "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                "email": values.email
                            }
                        }
                        await authService.IDPUserUpdate(body, token);
                        let bodyB = {
                            'uid': ediData._id,
                            'type': 'edit',
                            'data': {
                                "cName": values.cName,
                                "cEmail": values.cEmail,
                                "cPhone": values.cPhone,
                                "sdate": values.sdate,
                                "edate": values.edate,
                                "code": values.code,
                                "weburl": values.weburl,
                                "address": {
                                    "title": "Default",
                                    "address": values.address,
                                    "city": values.city,
                                    "state": values.state,
                                    "country": values.country,
                                    "zipcode": values.zipcode,
                                },
                                "profileMedia": ediData.profileMedia,
                            }
                        }
                        await profileService.updatePartner(bodyB, token)
                        toast.success("Partner updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        handleReset();
                        goBack();
                    })();
                }
            } else {
                if (file) {
                    setSubmit(true);

                    var formDataA = new FormData();
                    formDataA.append("path", 'partner');
                    formDataA.append("doc", file);

                    utilityService.uploadFile(formDataA, token).then((res) => {
                        if (res.status) {
                            let names = values.fullName.split(' ');
                            let body = {
                                "fname": names[0],
                                "mname": names.length > 2 ? names[1] : "NA",
                                "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                "email": values.email,
                                "cName": values.cName,
                                "cEmail": values.cEmail,
                                "cPhone": values.cPhone,
                                "sdate": values.sdate,
                                "edate": values.edate,
                                "code": values.code,
                                "weburl": values.weburl,
                                "address": {
                                    "title": "Default",
                                    "address": values.address,
                                    "city": values.city,
                                    "state": values.state,
                                    "country": values.country,
                                    "zipcode": values.zipcode,
                                },
                                "profileMedia": res.data,
                                "uid": getUID()
                            }
                            authService.partnerRegister(body, token).then((res) => {
                                if (res.status) {
                                    toast.success("New partner added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    goBack();
                                } else {
                                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                }
                                setSubmit(false);
                            });
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            setSubmit(false);
                            handleReset();
                        }
                    });
                } else {
                    toast.error("Please select a logo file.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            }
        }
    });

    const goBack = () => {
        setParam({});
        handleReset();
        props.onToggle();
        navigate(`/home/profileP`);
    }

    const getUID = () => {
        return "U" + Math.floor(Date.now() / 1000);
    }

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setFile(inputFile);

        const reader = new FileReader();
        reader.onload = () => {
            setPreviews(reader.result);
        };
        reader.readAsDataURL(inputFile);
    };

    const loadData = () => {
        (async function () {
            if (Object.keys(param).length === 0) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
                let body = {
                    'iid': param.eid
                };
                var partnerRes = await profileService.getPartnerById(body, token);
                const partner = partnerRes.data;
                setEditData(partner);
                setValues({
                    fullName: `${utilityService.getFullName(partner.user)}`,
                    email: partner.user.email,
                    cName: partner.companyName,
                    cEmail: partner.companyEmail,
                    cPhone: partner.companyPhone,
                    address: partner.companyAddr.address,
                    city: partner.companyAddr.city,
                    state: partner.companyAddr.state,
                    country: partner.companyAddr.country,
                    zipcode: partner.companyAddr.zipcode,
                    weburl: partner.companyWeb,
                    sdate: moment(partner.subscription.startDate).format('yyyy-MM-DD'),
                    edate: moment(partner.subscription.endDate).format('yyyy-MM-DD'),
                    code: partner.subscription.links
                });
                if (!utilityService.getIsObjectEmpty(partner.profileMedia)) {
                    setOldURL(partner.profileMedia.fileurl);
                }
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all partners</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Partners</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to partners.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Partners Company Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Company name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="cName" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. ISH" value={values.cName} />
                                                {(errors.cName && touched.cName) && <p className='text-xs text-red-400 mt-1'>{errors.cName}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="cEmail" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. ish@gmail.com" value={values.cEmail} />
                                                {(errors.cEmail && touched.cEmail) && <p className='text-xs text-red-400 mt-1'>{errors.cEmail}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Dedicated Helpline Number<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="cPhone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. +91 1234567890" value={values.cPhone} />
                                                {(errors.cPhone && touched.cPhone) && <p className='text-xs text-red-400 mt-1'>{errors.cPhone}</p>}
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Physical address<sup className="text-red-600">*</sup></label>
                                                <textarea id="address" value={values.address} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-none" placeholder="Street address"></textarea>
                                                {(errors.address && touched.address) && <p className='text-xs text-red-400 mt-1'>{errors.address}</p>}
                                            </div>

                                            <div className='col-span-6 grid grid-cols-2 gap-6'>
                                                <div className='col-span-1 grid grid-cols-1 gap-6'>
                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">City<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="city" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Mumbai" value={values.city} />
                                                        {(errors.city && touched.city) && <p className='text-xs text-red-400 mt-1'>{errors.city}</p>}
                                                    </div>

                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">State<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="state" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Maharashatra" value={values.state} />
                                                        {(errors.state && touched.state) && <p className='text-xs text-red-400 mt-1'>{errors.state}</p>}
                                                    </div>

                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Country<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="country" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. India" value={values.country} />
                                                        {(errors.country && touched.country) && <p className='text-xs text-red-400 mt-1'>{errors.country}</p>}
                                                    </div>

                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Pincode / Zipcode<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="zipcode" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 000001" value={values.zipcode} />
                                                        {(errors.zipcode && touched.zipcode) && <p className='text-xs text-red-400 mt-1'>{errors.zipcode}</p>}
                                                    </div>

                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Website URL<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="weburl" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. https://ishcallsign.com/" value={values.weburl} />
                                                        {(errors.weburl && touched.weburl) && <p className='text-xs text-red-400 mt-1'>{errors.weburl}</p>}
                                                    </div>
                                                </div>
                                                <div className='col-span-1'>
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Company Logo<sup className="text-red-600">*</sup></label>
                                                    <div className="py-2 bg-white">
                                                        <div>
                                                            {
                                                                file !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                                    <div className="w-full flex items-center justify-center py-4">
                                                                        <img src={previews} className='w-[400px] h-[400px]' />
                                                                    </div>
                                                                    <hr />
                                                                    <div className="flex justify-between mt-2">
                                                                        <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                            setFile(null);
                                                                            setPreviews(null);
                                                                        }}>
                                                                            <span>Remove</span>
                                                                        </label>
                                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                            <span>Replace</span>
                                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                (file === null && oldUrl === "") && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                                    <div className="space-y-1 text-center">
                                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                        <div className="flex text-sm text-gray-600">
                                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                                <span>Upload a file</span>
                                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                            </label>
                                                                            <p className="pl-1">or drag and drop</p>
                                                                        </div>
                                                                        <p className="text-xs text-gray-500">
                                                                            PNG, JPG, PDF up to 1MB
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                (file === null && oldUrl !== "") && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                                    <div className="w-full flex items-center justify-center py-4">
                                                                        <img src={oldUrl} className='w-[400px] h-[400px]' />
                                                                    </div>
                                                                    <hr />
                                                                    <div className="flex justify-between mt-2">
                                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                            <span>Replace</span>
                                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Partners Admin Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Admin Full Name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="fullName" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Rajesh Sukesh Varma" value={values.fullName} />
                                                {(errors.fullName && touched.fullName) && <p className='text-xs text-red-400 mt-1'>{errors.fullName}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Email address" value={values.email} />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Partners Subscrption Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Start Date<sup className="text-red-600">*</sup></label>
                                                <input type="date" id="sdate" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. DD-MM-YYYY" value={values.sdate} />
                                                {(errors.sdate && touched.sdate) && <p className='text-xs text-red-400 mt-1'>{errors.sdate}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">End Date<sup className="text-red-600">*</sup></label>
                                                <input type="date" id="edate" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. DD-MM-YYYY" value={values.edate} />
                                                {(errors.edate && touched.edate) && <p className='text-xs text-red-400 mt-1'>{errors.edate}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Partner Link<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="code" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. ABCD" value={values.code} />
                                                {(errors.code && touched.code) && <p className='text-xs text-red-400 mt-1'>{errors.code}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && (isEdit ? "UPDATE PARTNER" : "ADD PARTNER")}
                        </button>
                    </div>
                </form>
            </div >
        </div>
    )
}
