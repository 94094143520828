import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { modeAtom, authAtom } from './core/config/atoms';

import { ToastContainer } from 'react-toastify';

import PageAuth from './pages/PageAuth';
import PageHome from './pages/PageHome';
import Page404 from './pages/Page404';

export default function App() {

    const [modeStatus] = useRecoilState(modeAtom);
    const [authStatus] = useRecoilState(authAtom);

    useEffect(() => {
        const bodyEl = document.querySelector("body");
        modeStatus ? bodyEl.classList.add("dark") : bodyEl.classList.remove("dark");
    }, [modeStatus]);

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} rtl={false} theme="light" />
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageAuth />} />
                    {authStatus && <Route exact path="/home/:page/*" element={<PageHome />} />}
                    {authStatus && <Route exact path="/home/:page/:id/*" element={<PageHome />} />}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}