import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import { Menu, Transition } from '@headlessui/react';
import { motion } from "framer-motion";

export default function ComponentTechList() {

    const [token] = useRecoilState(tokenAtom);

    const [techs, setTechs] = useState([]);

    const [loading, setLoading] = useState(true);

    const loadData = () => {
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-4 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>Split of Ticket Types</h3>
                </div>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                {
                    (techs.length == 0 && !loading) && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                        <p className='text-center'>No Data Found.</p>
                    </div>
                }
            </div>
        </div>
    )
}
