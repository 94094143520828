import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceDisbilitie {
    lookupService = new APIServiceLookUp();

    async setDisbilitie(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}disbilitie/setDisbilitie`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateDisbilitie(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}disbilitie/updateDisbilitie`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllDisbilitie(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}disbilitie/getAllDisbilitie`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getDisbilitieOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}disbilitie/getDisbilitieOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delDisbilitie(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}disbilitie/delDisbilitie`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}