import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, paramAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import ServiceAuth from 'services/serviceAuth';
import ServiceStaff from 'services/serviceStaff';
import ServiceUtility from 'services/serviceUtility';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

const allowedExtensions = ["png", "jpeg"];

export default function PanelStaffAdd() {

    const [token] = useRecoilState(tokenAtom);
    const [param, setParam] = useRecoilState(paramAtom);

    const [file, setFile] = useState(null);
    const [previews, setPreviews] = useState(null);

    const [ediData, setEditData] = useState({});

    const [oldUrl, setOldURL] = useState('');

    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const staffService = new ServiceStaff();
    const utilityService = new ServiceUtility();

    const userTypes = [
        { value: 'admin', label: 'Admin' },
        { value: 'maneger', label: 'Maneger' },
    ];

    const formVSchema = Yup.object().shape({
        fullName: Yup.string().required('This information is required'),
        email: Yup.string().required('This information is required').email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }),
        uType: Yup.string().required('This information is required')
    });

    const getUID = () => {
        return "U" + Math.floor(Date.now() / 1000);
    }

    const { values, errors, touched, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            phone: '',
            uType: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (isEdit) {
                setSubmit(true);
                if (file) {
                    (async function () {
                        let names = values.fullName.split(' ');
                        let body = {
                            "uid": ediData.userId,
                            "data": {
                                "fname": names[0],
                                "mname": names.length > 2 ? names[1] : "NA",
                                "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                "email": values.email,
                                "phone": values.phone
                            }
                        }
                        await authService.IDPUserUpdate(body, token);

                        if (oldUrl === "") {
                            var formDataA = new FormData();
                            formDataA.append("path", 'user');
                            formDataA.append("doc", file);

                            const resUp = await utilityService.uploadFile(formDataA, token);
                            if (resUp.status) {
                                let body = {
                                    'uid': ediData._id,
                                    'data': {
                                        "type": values.uType,
                                        "profileMedia": resUp.data,
                                    }
                                }
                                await staffService.updateStaff(body, token)
                                toast.success("Staff updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                goBack();
                            } else {
                                toast.error(resUp.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        } else {
                            var formDataA = new FormData();
                            formDataA.append("path", oldUrl);
                            formDataA.append("doc", file);

                            const resUp = await utilityService.replaceFile(formDataA, token);
                            if (resUp.status) {
                                let body = {
                                    'uid': ediData._id,
                                    'data': {
                                        "type": values.uType,
                                        "profileMedia": resUp.data,
                                    }
                                }
                                await staffService.updateStaff(body, token)
                                toast.success("Staff updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                goBack();
                            } else {
                                toast.error(resUp.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        }
                    })();
                } else {
                    let names = values.fullName.split(' ');
                    let body = {
                        "uid": ediData.userId,
                        "data": {
                            "fname": names[0],
                            "mname": names.length > 2 ? names[1] : "NA",
                            "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                            "email": values.email,
                            "phone": values.phone
                        }
                    }
                    authService.IDPUserUpdate(body, token).then((res) => {
                        if (res.status) {
                            let body = {
                                'uid': ediData._id,
                                'data': {
                                    "type": values.uType,
                                    "profileMedia": ediData.profileMedia,
                                }
                            }
                            staffService.updateStaff(body, token).then((res) => {
                                if (res.status) {
                                    toast.success("Staff updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    goBack();
                                } else {
                                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                }
                                setSubmit(false);
                            });
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            setSubmit(false);
                        }
                    });
                }
            } else {
                setSubmit(true);
                if (file) {
                    var formDataA = new FormData();
                    formDataA.append("path", 'user');
                    formDataA.append("doc", file);

                    utilityService.uploadFile(formDataA, token).then((res) => {
                        if (res.status) {
                            let names = values.fullName.split(' ');
                            let body = {
                                "fname": names[0],
                                "mname": names.length > 2 ? names[1] : "NA",
                                "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                "email": values.email,
                                "phone": values.phone,
                                "type": values.uType,
                                "profileMedia": res.data,
                                "uid": getUID()
                            }
                            authService.staffRegister(body, token).then((resB) => {
                                if (resB.status) {
                                    toast.success("New staff added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    goBack();
                                } else {
                                    toast.error(resB.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                }
                                setSubmit(false);
                            });
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                } else {
                    let names = values.fullName.split(' ');
                    let body = {
                        "fname": names[0],
                        "mname": names.length > 2 ? names[1] : "NA",
                        "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                        "email": values.email,
                        "phone": values.phone,
                        "type": values.uType,
                        "profileMedia": {},
                        "uid": getUID()
                    }
                    authService.staffRegister(body, token).then((res) => {
                        if (res.status) {
                            toast.success("New staff added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            goBack();
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setSubmit(false);
                    });
                }
            }
        },
    });

    const goBack = () => {
        setParam({});
        navigate(`/home/staff`);
    }

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setFile(inputFile);

        const reader = new FileReader();
        reader.onload = () => {
            setPreviews(reader.result);
        };
        reader.readAsDataURL(inputFile);
    };

    const loadData = () => {
        (async function () {
            if (Object.keys(param).length === 0) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
                let body = {
                    'sid': param.eid
                };
                var staffRes = await staffService.getStaffById(body, token);
                const staff = staffRes.data;
                setEditData(staff);
                setValues({
                    fullName: utilityService.getFullName(staff.user),
                    email: staff.user.email,
                    phone: staff.user.phone,
                    uType: staff.type
                });
                if (!utilityService.getIsObjectEmpty(staff.profileMedia)) {
                    setOldURL(staff.profileMedia.fileurl);
                }
            }
        })();
    }

    const deleteProfile = () => {
        let body = {
            'path': ediData.profileMedia.fileurl
        }
        utilityService.deleteFile(body, token).then((res) => {
            let body = {
                'uid': ediData._id,
                'data': {
                    'profileMedia': {},
                }
            }
            staffService.updateStaff(body, token).then((res) => {
                if (res.status) {
                    toast.success("Staff updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    goBack();
                } else {
                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setSubmit(false);
            });
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all staff</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Staff</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to staff.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Staff Information</h3>
                                            </div>

                                            <div className='col-span-3 flex flex-col'>
                                                <div className="w-full mt-4">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Staff Type<sup className="text-red-600">*</sup></label>
                                                    <select id="uType" className="appearance-none w-full mt-1 p-2 bg-white text-sm border-gray-300 border rounded" onChange={handleChange} value={values.uType}>
                                                        <option value="" disabled="" selected="selected">Select</option>
                                                        {
                                                            userTypes.length !== 0 && userTypes.map((item) => {
                                                                return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {(errors.uType && touched.uType) && <p className='text-xs text-red-400 mt-1'>{errors.uType}</p>}
                                                </div>

                                                <div className="w-full mt-4">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Full Name<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="fullName" onChange={handleChange} autoComplete="full-name" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Jhon Harward Deo" value={values.fullName} />
                                                    {(errors.fullName && touched.fullName) && <p className='text-xs text-red-400 mt-1'>{errors.fullName}</p>}
                                                </div>

                                                <div className="w-full mt-4">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded-md outline-1 outline-blue-200" placeholder="Email address" value={values.email} />
                                                    {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                                </div>

                                                <div className="w-full mt-4">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Phone Number<sup className="text-gray-400">(optional)</sup></label>
                                                    <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded-md outline-1 outline-blue-200" placeholder="i.e. 1234567980" value={values.phone} />
                                                    {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                                </div>
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Profile Photo<sub className="text-gray-400">(optional)</sub></label>
                                                <div className="py-2 bg-white">
                                                    <div>
                                                        {
                                                            file !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                                <div className="w-full flex items-center justify-center py-4">
                                                                    <img src={previews} className='w-[400px] h-[400px]' />
                                                                </div>
                                                                <hr />
                                                                <div className="flex justify-between mt-2">
                                                                    <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                        setFile(null);
                                                                        setPreviews(null);
                                                                    }}>
                                                                        <span>Remove</span>
                                                                    </label>
                                                                    <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                        <span>Replace</span>
                                                                        <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            (file === null && oldUrl === "") && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                                <div className="space-y-1 text-center">
                                                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                    <div className="flex text-sm text-gray-600">
                                                                        <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                            <span>Upload a file</span>
                                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                        </label>
                                                                        <p className="pl-1">or drag and drop</p>
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">
                                                                        PNG, JPG, PDF up to 1MB
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            (file === null && oldUrl !== "") && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                                <div className="w-full flex items-center justify-center py-4">
                                                                    <img src={oldUrl} className='w-[400px] h-[400px]' />
                                                                </div>
                                                                <hr />
                                                                <div className="flex justify-between mt-2">
                                                                    <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                        deleteProfile();
                                                                    }}>
                                                                        <span>Remove</span>
                                                                    </label>
                                                                    <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                        <span>Replace</span>
                                                                        <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && (isEdit ? "UPDATE STAFF" : "ADD STAFF")}
                        </button>
                    </div>
                </form>
            </div >
        </div>
    )
}
