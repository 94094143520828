import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover, PopoverContent, PopoverTrigger
} from "@/components/ui/popover";

import { cn } from "@/lib/utils";

import { Calendar as CalendarIcon } from "lucide-react";

import { motion } from "framer-motion";
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { Menu, Transition } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

// import ServiceCustomer from '../../services/serviceCustomer';

export default function PanelReportTicket() {

    const [token] = useRecoilState(tokenAtom);

    const [rides, setRides] = useState([]);

    const [filter, setFilter] = useState({});

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);

    const navigate = useNavigate();
    // const customerService = new ServiceCustomer();

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setSDate(Moment().startOf('year').toDate());
        setEDate(Moment().toDate());
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "banned":
                setFilter({ 'status': 'banned' });
                break;
            case "flaged":
                setFilter({ 'status': 'flaged' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        rides.forEach((item) => {
            items.push({
                cid: item.cid,
                fname: item.fname,
                mname: item.mname,
                lname: item.lname,
                email: item.email,
                phone: item.phone,
                dob: item.dob,
                address: item.addresses[0].address,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        // const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, "customers");
        // XLSX.writeFile(workbook, "customers.xlsx");
    }

    const exportToCsv = () => {
        // let headers = ['cid,fname,mname,lname,email,phone,dob,status'];
        // let usersCsv = customers.reduce((acc, user) => {
        //     const { cid, fname, mname, lname, email, phone, dob, status } = user
        //     acc.push([cid, fname, mname, lname, email, phone, dob, status].join(','))
        //     return acc
        // }, []);
        // downloadFile({
        //     data: [...headers, ...usersCsv].join('\n'),
        //     fileName: 'customers.csv',
        //     fileType: 'text/csv',
        // });
    }

    const onDateChange = (start, end) => {
        setIsDate(true);
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        (async function () {
            // let body = {
            //     "filter": filter,
            //     "page": 1,
            //     "size": parseInt(pageSize),
            //     "searchQuery": query,
            //     "startDate": isDate ? sdate.toISOString() : "",
            //     "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            // };
            // var res = await customerService.getAllCustomers(body, token);
            // if (res['status']) {
            //     setPageCurrent(res.data.currentPage);
            //     setPageTotal(res.data.totalPage);
            //     setCustomers(res.data.data);
            // } else {
            //     alert.show('Server error please try again', { type: 'error' });
            // }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            // let body = {
            //     "filter": filter,
            //     "page": pageValue,
            //     "size": parseInt(pageSize),
            //     "searchQuery": query,
            //     "startDate": isDate ? sdate.toISOString() : "",
            //     "endDate": isDate ? Moment(edate).add(1, 'day').toDate().toISOString() : ""
            // };
            // var res = await customerService.getAllCustomers(body, token);
            // if (res['status']) {
            //     setPageCurrent(res.data.currentPage);
            //     setPageTotal(res.data.totalPage);
            //     setCustomers(res.data.data);
            // } else {
            //     alert.show('Server error please try again', { type: 'error' });
            // }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, sdate, edate, filter]);

    return (
        <div className="col-span-12 mt-4 px-2">
            <div className="w-full shadow-md rounded-md border">
                <table className="min-w-full divide-y divide-gray-200 px-4">
                    <thead className="bg-gray-50">
                        <tr className=''>
                            <th scope="col" className="border-b border-gray-200" colSpan={7}>
                                <div className='w-full grid grid-cols-12 gap-2'>
                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                        <div className='col-span-3 flex'>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button id="date" variant={"outline"}
                                                        className={cn(
                                                            "w-[300px] justify-start text-left font-normal",
                                                            !date && "text-muted-foreground"
                                                        )}
                                                    >
                                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                                        {date?.from ? (
                                                            date.to ? (
                                                                <>
                                                                    {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                    {Moment(date.to).format('MMM DD, yyyy')}
                                                                </>
                                                            ) : (
                                                                Moment(date.from).format('MMM DD, yyyy')
                                                            )
                                                        ) : (
                                                            <span>Pick a date</span>
                                                        )}
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0" align="start">
                                                    <Calendar
                                                        initialFocus
                                                        mode="range"
                                                        defaultMonth={date?.from}
                                                        selected={date}
                                                        onSelect={onDateChange}
                                                        numberOfMonths={2}
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                        <div className='w-full flex col-span-8 items-center'>
                                            <div className='w-[80px]'>
                                                <p>Search :</p>
                                            </div>
                                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                        </div>
                                    </form>
                                    <div className='col-span-1 flex items-center'>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger>
                                                <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <div
                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                    <div>
                                                        <ul className='flex flex-col items-start p-2'>
                                                            <DropdownMenuItem>
                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                    exportToExcel();
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                    </div>
                                                                </li>
                                                            </DropdownMenuItem>
                                                            <DropdownMenuItem>
                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                    exportToCsv();
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                    </div>
                                                                </li>
                                                            </DropdownMenuItem>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                TICKET ID
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                CUSTOMER DETAILS
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Interpreter DETAILS
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Ticket DETAILS
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Created At
                            </th>
                            <th scope="col" className="px-2 py-3"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {/* {
                            customers.length !== 0 && customers.map((customer) => {
                                return <tr className="hover:bg-gray-100" key={customer.cid}>
                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => {
                                        gotoCustomerInfo(customer._id);
                                    }}>
                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                            {customer.fname} {customer.mname} {customer.lname}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            {customer.email === "" ? "NA" : customer.email}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            +243 {customer.phone}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${customer.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                            {customer.status.toUpperCase()}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-blue-600">
                                            {Moment(customer.createdAt).format('DD, MMM yyyy')}
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            <Menu>
                                                <Menu.Button>
                                                    <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                </Menu.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0">
                                                    <Menu.Items>
                                                        <div
                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    {
                                                                        customer.status == 'active' && <Menu.Item>
                                                                            <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                updateStatus(customer.cid, 'banned');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-ban text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Block</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    }
                                                                    {
                                                                        customer.status == 'active' && <Menu.Item>
                                                                            <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                updateStatus(customer.cid, 'flaged');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-flag text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Flag</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    }
                                                                    {
                                                                        customer.status == 'flaged' && <Menu.Item>
                                                                            <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                updateStatus(customer.cid, 'active');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-chevron-circle-up text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Un-Flag</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    }
                                                                    {
                                                                        customer.status == 'banned' && <Menu.Item>
                                                                            <li className='w-32 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                updateStatus(customer.cid, 'active');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-chevron-circle-up text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Active</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    }
                                                                    {
                                                                        customer.status == 'banned' && <Menu.Item>
                                                                            <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                deleteCustomer(customer.cid);
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-trash text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Delete</p>
                                                                                </div>
                                                                            </li>
                                                                        </Menu.Item>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        }
                                    </td>
                                </tr>
                            })
                        } */}
                        {
                            rides.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                    <div className="text-sm text-blue-600 text-center">
                                        No data found.
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className='flex py-2 px-4 items-center bg-blue-50'>
                    <p className='text-sm font-serif'>Show rows per page</p>
                    <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                    <div className='flex-grow'></div>
                    <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                    <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                    <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                </div>
            </div>
        </div>
    )
}
