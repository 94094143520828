import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";

import { motion } from "framer-motion";
import Lottie from 'lottie-react';

import TopBar from '../../core/widgets/ui/TopBar';

import animPage from '../../assets/anim/anim-pageload.json';

import ComponentSalesCountCard from './components/card/ComponentSalesCountCard';
import ComponentOrderCountCard from './components/card/ComponentOrderCountCard';
import ComponentOrderPCountCard from './components/card/ComponentOrderPCountCard';
import ComponentOrderDCountCard from './components/card/ComponentOrderDCountCard';
import ComponentCustomerCountCard from './components/card/ComponentCustomerCountCard';

import ComponentNewOrderList from './components/list/ComponentNewOrderList';

import ComponentServicesList from './components/block/ComponentServicesList';
import ComponentTechList from './components/block/ComponentTechList';
import ComponentCompList from './components/block/ComponentCompList';

export default function PanelOverview(props) {

    const [loading, setLoading] = useState(true);

    const loadData = () => {
        (async function () {
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                        <h2 class="text-2xl font-medium truncate ml-2">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Overview Reports
                        </h2>
                        <TopBar />
                    </div>
                    <div class="w-full h-full py-3 px-3">
                        {
                            !loading && <>
                                <div className="grid grid-cols-4 lg:grid-cols-10 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4">
                                    <ComponentOrderCountCard />
                                    <ComponentOrderPCountCard />
                                    <ComponentOrderDCountCard />
                                    <ComponentCustomerCountCard />
                                    <ComponentSalesCountCard />
                                </div>
                                <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                                    <ComponentServicesList />
                                    <ComponentCompList />
                                    <ComponentTechList />
                                </div>
                                <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                                    <ComponentNewOrderList />
                                </div>
                                <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'></div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
