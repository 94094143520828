import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAttandance {
    lookupService = new APIServiceLookUp();

    async getAllAttandance(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}attendance/getAllAttandance`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}