import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";
import {
    DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";

import { motion } from "framer-motion";
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import moment from 'moment';
import momentZ from 'moment-timezone';
import Select from 'react-select';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceCountry from 'services/serviceCountry';

export default function PanelMarCountry(props) {
    const [token] = useRecoilState(tokenAtom);

    const [data, setData] = useState([]);
    const [timeZones, setTimeZones] = useState(momentZ.tz.names().map((tz) => ({
        value: tz,
        label: tz,
    })));

    const [editId, setEditId] = useState('');

    const [isOpenMain, setIsOpenMain] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const countryService = new ServiceCountry();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
        prefix: Yup.string().required('This information is required'),
        index: Yup.number().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            title: '',
            prefix: '',
            index: 0,
            tz: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (values.tz === '') {
                toast.error('Please enter all the required information', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                if (isOpenMain && !isUpdate) {
                    setSubmit(true);
                    let body = {
                        "title": values.title,
                        "prefix": values.prefix,
                        "index": values.index,
                        "tz": values.tz.value,
                    }
                    countryService.setCountry(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            toast.success('New Country Added', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            loadData();
                        } else {
                            toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setIsOpenMain(false);
                        setIsUpdate(false);
                        handleReset();
                    });
                }

                if (isOpenMain && isUpdate) {
                    setSubmit(true);
                    let body = {
                        "oid": editId,
                        "data": {
                            "title": values.title,
                            "prefix": values.prefix,
                            "index": values.index,
                            "tz": values.tz.value,
                        },
                    }
                    countryService.updateCountry(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            toast.success('Country Updated', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            loadData();
                        } else {
                            toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setIsOpenMain(false);
                        setIsUpdate(false);
                        setEditId('');
                        handleReset();
                    });
                }
            }
        }
    });

    const editMar = (item) => {
        let data = {
            title: item.title,
            prefix: item.prefix,
            index: item.index,
            tz: { value: item.tz, label: item.tz },
        }
        setEditId(item._id);
        setIsUpdate(true);
        setValues(data);
        setIsOpenMain(true);
    }

    const delMar = (id) => {
        let data = {
            "oid": id
        }
        countryService.delCountry(data, token).then((res) => {
            if (res.status) {
                toast.success('Country Deleted', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                loadData();
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    }

    const loadData = () => {
        (async function () {
            let body = {};
            var res = await countryService.getAllCountry(body, token);
            if (res['status']) {
                const sortedData = res.data.slice().sort((a, b) => {
                    return a.index - b.index;
                });
                setData(sortedData);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className='h-screen w-full'>
            <Dialog open={isOpenMain} onClose={() => setIsOpenMain(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Country &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenMain(false);
                            setIsUpdate(false);
                            handleReset();
                            document.getElementById("mar-form").reset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} id="mar-form">
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Country<sup className="text-red-600">*</sup></label>
                            <input type="text" id="title" name="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. India" onChange={handleChange} />
                            {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Prefix<sup className="text-red-600">*</sup></label>
                            <input type="text" id="prefix" name="prefix" value={values.prefix} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. +91" onChange={handleChange} />
                            {(errors.prefix && touched.prefix) && <p className='text-xs text-red-400 mt-1'>{errors.prefix}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Index <sup className="text-red-600">*</sup></label>
                            <input type="text" id="index" name="index" value={values.index} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. 1" onChange={handleChange} />
                            {(errors.index && touched.index) && <p className='text-xs text-red-400 mt-1'>{errors.index}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Timezone <sup className="text-red-600">*</sup></label>
                            <Select options={timeZones} id="tz" name="tz" isMulti={false} onChange={(e) => {
                                setValues({ ...values, tz: e });
                            }} value={values.tz} className="col-span-6 shadow-inner" />
                            {(errors.tz && touched.tz) && <p className='text-xs text-red-400 mt-1'>{errors.tz}</p>}
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && "ADD"}
                                {!submit && isUpdate && "UPDATE"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Country Master
                            </h2>
                            <div className='flex'>
                                <div>
                                    <button className="h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white mr-4 text-sm" onClick={() => {
                                        setIsOpenMain(true);
                                    }}><i class="las la-plus mr-1"></i> ADD COUNTRY</button>
                                </div>
                                <TopBar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Sr No.
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Title
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Prefix
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Index
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Timezone
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-2 py-3"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    data.length !== 0 && data.map((item, index) => {
                                        return <tr className="hover:bg-gray-100" key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                <div className="text-sm font-bold text-gray-900">
                                                    <div className='flex items-center text-prime'>
                                                        {(index + 1)}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900 uppercase">
                                                    {item.title}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900 uppercase">
                                                    ({item.prefix})
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {item.index}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {item.tz}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-prime">
                                                    {moment(item.createdAt).format('DD MMM, yyyy')}
                                                </div>
                                            </td>
                                            <td>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <div
                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-32px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    <DropdownMenuItem>
                                                                        <li className='w-32 cursor-pointer px-2 py-1 rounded-md' onClick={() => {
                                                                            editMar(item);
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-pen text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Edit</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem>
                                                                        <li className='w-32 cursor-pointer px-2 py-1 rounded-md' onClick={() => {
                                                                            delMar(item._id);
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-trash text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Delete</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    data.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                            <div className="text-sm text-red-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
