import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceHelp {
    lookupService = new APIServiceLookUp();

    async setHelp(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}help/setHelp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateHelp(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}help/updateHelp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllHelp(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}help/getAllHelp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getHelpByFilter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}help/getHelpByFilter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delHelp(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}help/delHelp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}