import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceCountry {
    lookupService = new APIServiceLookUp();

    async setCountry(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}country/setCountry`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCountry(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}country/updateCountry`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllCountry(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}country/getAllCountry`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getCountryOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}country/getCountryOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delCountry(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}country/delCountry`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}