import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import moment from 'moment';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import animPage from '../../../assets/anim/anim-pageload.json';

import ServiceProfiles from 'services/serviceProfiles';
import ServiceUtility from 'services/serviceUtility';

import PaneTicketPartner from '../panes/PaneTicketPartner';

import PanelDev from 'panels/dev/PanelDev';

export default function ComponentPartnerInfo({ cid }) {

    const [token] = useRecoilState(tokenAtom);

    const [user, setUser] = useState({});

    const [tab, setTab] = useState('a');

    const [loading, setLoading] = useState(true);

    const profileService = new ServiceProfiles();
    const utilityService = new ServiceUtility();

    const gotoNav = (code) => {
        window.open(`https://partner.ishcallsign.com/${code}`, '_blank');
    }

    const gotoWeb = (link) => {
        if (link !== "") {
            window.open(`${link}`, '_blank');
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                'iid': cid
            };
            var res = await profileService.getPartnerById(body, token);
            if (res['status']) {
                setUser(res.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, [cid]);

    return (
        <div>
            {
                loading && <div className='flex items-center justify-center w-full h-screen'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                !loading && <div>
                    <div className='h-[320px] bg-blue-50 px-4 relative'>
                        <div className='h-[220px] bg-blue-600 rounded-b-md'></div>
                        <div className='w-[180px] h-[180px] bg-white border-[6px] border-blue-50 rounded-full absolute top-[120px] ml-8'>
                            <img src={utilityService.getIsObjectEmpty(user.profileMedia) ? "https://storage.googleapis.com/ish-prod/ish-assets/staff.png" : user.profileMedia.fileurl} alt='Profile Image' className='w-full h-full rounded-full' />
                        </div>
                        <h1 className='ml-[220px] text-4xl font-medium absolute'>{user.companyName}</h1>
                    </div>
                    <div className='bg-gray-50 px-12 py-4'>
                        <div className='bg-white w-full shadow rounded-md p-4 flex'>
                            <div className='w-[30%]'>
                                <h2 className='text-xl font-medium'>About</h2>
                                <ul className='mt-2'>
                                    <li className={`text-sm font-medium py-1 px-2 rounded-md ${tab === "a" ? "text-blue-600 bg-blue-50 hover:bg-blue-50" : "bg-gray-50 hover:bg-blue-100"} cursor-pointer`} onClick={() => { setTab('a') }}>Partner Information</li>
                                    <li className={`text-sm font-medium py-1 px-2 rounded-md ${tab === "b" ? "text-blue-600 bg-blue-50 hover:bg-blue-50" : "bg-gray-50 hover:bg-blue-100"} cursor-pointer my-3`} onClick={() => { setTab('b') }}>Admin Information</li>
                                    <li className={`text-sm font-medium py-1 px-2 rounded-md ${tab === "c" ? "text-blue-600 bg-blue-50 hover:bg-blue-50" : "bg-gray-50 hover:bg-blue-100"} cursor-pointer my-3`} onClick={() => { setTab('c') }}>Subscription Information</li>
                                </ul>
                            </div>
                            <div className='w-[1px] bg-gray-200 mx-2'></div>
                            {
                                (() => {
                                    switch (tab) {
                                        case 'a':
                                            return <div className='flex-grow'>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Partner Name</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.companyName}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Email Address</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.companyEmail}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Phone Number</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.companyPhone}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Physical Address</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.companyAddr.address}, {user.companyAddr.city}, {user.companyAddr.state}, {user.companyAddr.country} - {user.companyAddr.zipcode}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Dedicated Helpline Number</div>
                                                    <div className="text-slate-950 text-sm font-medium">+91 {user.companyPhone}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Website</div>
                                                    <div className="text-blue-600 text-sm font-medium" onClick={() => { gotoWeb(user.companyWeb) }}>{user.companyWeb === "" ? "-" : user.companyWeb}</div>
                                                </div>
                                            </div>;
                                        case 'b':
                                            return <div className='flex-grow'>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Admin User Name</div>
                                                    <div className="text-slate-950 text-sm font-medium">{utilityService.getFullName(user["user"])}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Email Address</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.user.email}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Phone Number</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.user.phone === "" ? "-" : `+91 ${user.user.phone}`}</div>
                                                </div>
                                            </div>
                                        case 'c':
                                            return <div className='flex-grow'>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Start Date</div>
                                                    <div className="text-slate-950 text-sm font-medium">{moment(user.subscription['startDate']).format('DD MMM yyyy')}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">End Date</div>
                                                    <div className="text-slate-950 text-sm font-medium">{moment(user.subscription['endDate']).format('DD MMM yyyy')}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">CODE LINK</div>
                                                    <div className="text-blue-600 text-sm font-medium cursor-pointer" onClick={() => { gotoNav(user.subscription.links) }}>{user.subscription.links}</div>
                                                </div>
                                            </div>
                                        default:
                                            return (<PanelDev />);
                                    }
                                })()
                            }
                        </div>
                        <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                            <h2 className='text-xl font-medium'>Activity</h2>
                            <div class="w-full mt-2">
                                <div className="py-2 mt-2">
                                    <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                        <li className="mx-2"><button className="py-2 px-2 text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500">Ticket Report</button></li>
                                    </ul>
                                </div>
                            </div>
                            <PaneTicketPartner pid={user.subscription.partnerId} role='admin' />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
