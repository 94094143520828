import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, paramAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';

import ServiceAuth from 'services/serviceAuth';
import ServiceLang from 'services/serviceLang';
import ServiceUtility from 'services/serviceUtility';
import ServiceProfiles from 'services/serviceProfiles';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

const allowedExtensions = ["png", "jpeg"];

export default function PanelProfileIAdd(props) {

    const [token] = useRecoilState(tokenAtom);
    const [param, setParam] = useRecoilState(paramAtom);

    const [langOptions, setLangOptions] = useState([]);
    const [selLang, setSelLang] = useState([]);

    const [ediData, setEditData] = useState({});

    const [oldUrl, setOldURL] = useState('');

    const [file, setFile] = useState(null);
    const [previews, setPreviews] = useState(null);

    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const langService = new ServiceLang();
    const utilityService = new ServiceUtility();
    const profileService = new ServiceProfiles();

    const userTypes = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
    ];

    const formVSchema = Yup.object().shape({
        fullName: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address').required('This information is required'),
        gender: Yup.string().required('This information is required'),
        dob: Yup.string().required('This information is required'),
        address: Yup.string().required('This information is required'),
        city: Yup.string().required('This information is required'),
        state: Yup.string().required('This information is required'),
        country: Yup.string().required('This information is required'),
        zipcode: Yup.string().required('This information is required'),
    });

    const getUID = () => {
        return "U" + Math.floor(Date.now() / 1000);
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            phone: '',
            gender: '',
            dob: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            langs: [],
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (values.langs.length === 0) {
                toast.error("Please select at least one language to continue.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                if (isEdit) {
                    setSubmit(true);
                    if (file) {
                        (async function () {
                            let names = values.fullName.split(' ');
                            let body = {
                                "uid": ediData.userId,
                                "data": {
                                    "fname": names[0],
                                    "mname": names.length > 2 ? names[1] : "NA",
                                    "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                    "email": values.email,
                                    "phone": values.phone,
                                    "dob": values.dob,
                                    "address": [{
                                        "title": "Default",
                                        "address": values.address,
                                        "city": values.city,
                                        "state": values.state,
                                        "country": values.country,
                                        "zipcode": values.zipcode,
                                    }],
                                }
                            }
                            await authService.IDPUserUpdate(body, token);

                            if (oldUrl === "") {
                                var formDataA = new FormData();
                                formDataA.append("path", 'user');
                                formDataA.append("doc", file);

                                const resUp = await utilityService.uploadFile(formDataA, token);
                                if (resUp.status) {
                                    let body = {
                                        'uid': ediData._id,
                                        'data': {
                                            "langs": values.langs,
                                            "gender": values.gender,
                                            "profileMedia": resUp.data,
                                        }
                                    }
                                    await profileService.updateInterpreter(body, token)
                                    toast.success("Interpreter updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    goBack();
                                } else {
                                    toast.error(resUp.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                }
                            } else {
                                var formDataA = new FormData();
                                formDataA.append("path", oldUrl);
                                formDataA.append("doc", file);

                                const resUp = await utilityService.replaceFile(formDataA, token);
                                if (resUp.status) {
                                    let body = {
                                        'uid': ediData._id,
                                        'data': {
                                            "langs": values.langs,
                                            "gender": values.gender,
                                            "profileMedia": resUp.data,
                                        }
                                    }
                                    await profileService.updateInterpreter(body, token)
                                    toast.success("Interpreter updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    goBack();
                                } else {
                                    toast.error(resUp.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                }
                            }
                        })();
                    } else {
                        let names = values.fullName.split(' ');
                        let body = {
                            "uid": ediData.userId,
                            "data": {
                                "fname": names[0],
                                "mname": names.length > 2 ? names[1] : "NA",
                                "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                "email": values.email,
                                "phone": values.phone,
                                "dob": values.dob,
                                "addresses": [{
                                    "title": "Default",
                                    "address": values.address,
                                    "city": values.city,
                                    "state": values.state,
                                    "country": values.country,
                                    "zipcode": values.zipcode,
                                }],
                            }
                        }
                        authService.IDPUserUpdate(body, token).then((res) => {
                            if (res.status) {
                                let body = {
                                    'uid': ediData._id,
                                    'data': {
                                        "langs": values.langs,
                                        "gender": values.gender,
                                        "profileMedia": ediData.profileMedia,
                                    }
                                }
                                profileService.updateInterpreter(body, token).then((res) => {
                                    if (res.status) {
                                        toast.success("Interpreter updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                        goBack();
                                    } else {
                                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    }
                                    setSubmit(false);
                                });
                            } else {
                                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                setSubmit(false);
                            }
                        });
                    }
                } else {
                    setSubmit(true);
                    if (file) {
                        var formDataA = new FormData();
                        formDataA.append("path", 'user');
                        formDataA.append("doc", file);

                        utilityService.uploadFile(formDataA, token).then((res) => {
                            if (res.status) {
                                let names = values.fullName.split(' ');
                                let body = {
                                    "fname": names[0],
                                    "mname": names.length > 2 ? names[1] : "NA",
                                    "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                                    "email": values.email,
                                    "phone": values.phone,
                                    "gender": values.gender,
                                    "dob": values.dob,
                                    "langs": values.langs,
                                    "address": {
                                        "title": "Default",
                                        "address": values.address,
                                        "city": values.city,
                                        "state": values.state,
                                        "country": values.country,
                                        "zipcode": values.zipcode,
                                    },
                                    "profileMedia": res.data,
                                    "uid": getUID()
                                }
                                authService.interpreterRegister(body, token).then((res) => {
                                    if (res.status) {
                                        toast.success("New interpreter added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                        goBack();
                                    } else {
                                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    }
                                    setSubmit(false);
                                });
                            } else {
                                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    } else {
                        let names = values.fullName.split(' ');
                        let body = {
                            "fname": names[0],
                            "mname": names.length > 2 ? names[1] : "NA",
                            "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                            "email": values.email,
                            "phone": values.phone,
                            "gender": values.gender,
                            "dob": values.dob,
                            "langs": values.langs,
                            "address": {
                                "title": "Default",
                                "address": values.address,
                                "city": values.city,
                                "state": values.state,
                                "country": values.country,
                                "zipcode": values.zipcode,
                            },
                            "profileMedia": {},
                            "uid": getUID()
                        }
                        authService.interpreterRegister(body, token).then((res) => {
                            if (res.status) {
                                toast.success("New interpreter added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                goBack();
                            } else {
                                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                            setSubmit(false);
                        });
                    }
                }
            }
        },
    });

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setFile(inputFile);

        const reader = new FileReader();
        reader.onload = () => {
            setPreviews(reader.result);
        };
        reader.readAsDataURL(inputFile);
    };

    const goBack = () => {
        setParam({});
        props.onToggle();
        navigate(`/home/profileI`);
    }

    const updateLangsInValues = (e) => {
        setSelLang(e);
        let TempLangs = [];
        e.map((val) => {
            TempLangs.push(val.value);
        });
        setValues({ ...values, langs: TempLangs });
    }

    const loadData = () => {
        (async function () {
            var langRes = await langService.getLangOptions({}, token);
            setLangOptions(langRes.data);

            if (Object.keys(param).length === 0) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
                let body = {
                    'iid': param.eid
                };
                var interpreterRes = await profileService.getInterpreterById(body, token);
                const interpreter = interpreterRes.data;
                setEditData(interpreter);
                setValues({
                    fullName: `${utilityService.getFullName(interpreter.user)}`,
                    email: interpreter.user.email,
                    phone: interpreter.user.phone,
                    gender: interpreter.gender,
                    dob: moment(interpreter.user.dob).format('yyyy-MM-DD'),
                    address: interpreter.user.addresses.length === 0 ? "" : interpreter.user.addresses[0].address,
                    city: interpreter.user.addresses.length === 0 ? "" : interpreter.user.addresses[0].city,
                    state: interpreter.user.addresses.length === 0 ? "" : interpreter.user.addresses[0].state,
                    country: interpreter.user.addresses.length === 0 ? "" : interpreter.user.addresses[0].country,
                    zipcode: interpreter.user.addresses.length === 0 ? "" : interpreter.user.addresses[0].zipcode,
                    langs: interpreter.langs,
                });
                const tempList = [];
                interpreter.langs.map((lng) => {
                    tempList.push({ value: lng, label: lng });
                })
                setSelLang(tempList);

                if (!utilityService.getIsObjectEmpty(interpreter.profileMedia)) {
                    setOldURL(interpreter.profileMedia.fileurl);
                }
            }
        })();
    }

    const deleteProfile = () => {
        let body = {
            'path': ediData.profileMedia.fileurl
        }
        utilityService.deleteFile(body, token).then((res) => {
            let body = {
                'uid': ediData._id,
                'data': {
                    'profileMedia': {},
                }
            }
            profileService.updateInterpreter(body, token).then((res) => {
                if (res.status) {
                    toast.success("Interpreter updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    goBack();
                } else {
                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setSubmit(false);
            });
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all interpreters</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Interpreter</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to interpreter.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Interpreter Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Full name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="fullName" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Ramesh Suresh Mishra" value={values.fullName} />
                                                {(errors.fullName && touched.fullName) && <p className='text-xs text-red-400 mt-1'>{errors.fullName}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. ramesh@gmai.com" value={values.email} />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Phone Number<sub className="text-gray-600">(optional)</sub></label>
                                                <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1234567980" value={values.phone} />
                                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Gender<sup className="text-red-600">*</sup></label>
                                                <select id="gender" className="appearance-none mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" onChange={handleChange} value={values.gender}>
                                                    <option value="" disabled="" selected="selected">Select</option>
                                                    {
                                                        userTypes.length !== 0 && userTypes.map((item) => {
                                                            return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                        })
                                                    }
                                                </select>
                                                {(errors.gender && touched.gender) && <p className='text-xs text-red-400 mt-1'>{errors.gender}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Date of Birth<sup className="text-red-600">*</sup></label>
                                                <input type="date" id="dob" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 12/23/1995" value={values.dob} />
                                                {(errors.dob && touched.dob) && <p className='text-xs text-red-400 mt-1'>{errors.dob}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Language<sup className="text-red-600">*</sup></label>
                                                <Select isClearable options={langOptions} isMulti={true} onChange={(e) => {
                                                    updateLangsInValues(e);
                                                }} name="category" id="category" value={selLang} className="mt-1 bg-white text-sm" />
                                                {(errors.langs && touched.langs) && <p className='text-xs text-red-400 mt-1'>{errors.langs}</p>}
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Physical address<sup className="text-red-600">*</sup></label>
                                                <textarea id="address" value={values.address} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Street address"></textarea>
                                                {(errors.address && touched.address) && <p className='text-xs text-red-400 mt-1'>{errors.address}</p>}
                                            </div>

                                            <div className='col-span-6 grid grid-cols-2 gap-6'>
                                                <div className='col-span-1 grid grid-cols-1 gap-6'>
                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">City<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="city" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Mumbai" value={values.city} />
                                                        {(errors.city && touched.city) && <p className='text-xs text-red-400 mt-1'>{errors.city}</p>}
                                                    </div>

                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">State<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="state" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Maharashatra" value={values.state} />
                                                        {(errors.state && touched.state) && <p className='text-xs text-red-400 mt-1'>{errors.state}</p>}
                                                    </div>

                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Country<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="country" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. India" value={values.country} />
                                                        {(errors.country && touched.country) && <p className='text-xs text-red-400 mt-1'>{errors.country}</p>}
                                                    </div>

                                                    <div className="col-span-1 mb-4">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Pincode / Zipcode<sup className="text-red-600">*</sup></label>
                                                        <input type="text" id="zipcode" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 000001" value={values.zipcode} />
                                                        {(errors.zipcode && touched.zipcode) && <p className='text-xs text-red-400 mt-1'>{errors.zipcode}</p>}
                                                    </div>
                                                </div>
                                                <div className='col-span-1'>
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Profile Photo<sub className="text-gray-400">(optional)</sub></label>
                                                    <div className="py-2 bg-white">
                                                        <div>
                                                            {
                                                                file !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                                    <div className="w-full flex items-center justify-center py-4">
                                                                        <img src={previews} className='w-[400px] h-[400px]' />
                                                                    </div>
                                                                    <hr />
                                                                    <div className="flex justify-between mt-2">
                                                                        <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                            setFile(null);
                                                                            setPreviews(null);
                                                                        }}>
                                                                            <span>Remove</span>
                                                                        </label>
                                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                            <span>Replace</span>
                                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                (file === null && oldUrl === "") && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                                    <div className="space-y-1 text-center">
                                                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                        <div className="flex text-sm text-gray-600">
                                                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                                <span>Upload a file</span>
                                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                            </label>
                                                                            <p className="pl-1">or drag and drop</p>
                                                                        </div>
                                                                        <p className="text-xs text-gray-500">
                                                                            PNG, JPG, PDF up to 1MB
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                (file === null && oldUrl !== "") && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                                    <div className="w-full flex items-center justify-center py-4">
                                                                        <img src={oldUrl} className='w-[400px] h-[400px]' />
                                                                    </div>
                                                                    <hr />
                                                                    <div className="flex justify-between mt-2">
                                                                        <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                            deleteProfile();
                                                                        }}>
                                                                            <span>Remove</span>
                                                                        </label>
                                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                            <span>Replace</span>
                                                                            <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && (isEdit ? "UPDATE INTERPRETER" : "ADD INTERPRETER")}
                        </button>
                    </div>
                </form>
            </div >
        </div>
    )
}
