import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutHome from '../layouts/LayoutHome';
import LayoutTicket from '../layouts/LayoutTicket';
import LayoutProfile from '../layouts/LayoutProfile';
import LayoutStaff from '../layouts/LayoutStaff';
import LayoutReports from '../layouts/LayoutReports';
import LayoutSetting from '../layouts/LayoutSetting';

export default function PageHome() {

    const { page } = useParams();

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {(() => {
                switch (page) {
                    case 'main':
                        return (<LayoutHome />);
                    case 'ticket':
                    case 'ticketLive':
                        return (<LayoutTicket />);
                    case 'profile':
                    case 'profileI':
                    case 'ProfileIAdd':
                    case 'interpreterInfo':
                    case 'profileP':
                    case 'ProfilePAdd':
                    case 'ProfilePStyle':
                    case 'partnerInfo':
                        return (<LayoutProfile />);
                    case 'staff':
                    case 'staffAdd':
                        return (<LayoutStaff />);
                    case 'reports':
                        return (<LayoutReports />);
                    case 'setting':
                    case 'company':
                    case 'marLang':
                    case 'marCountry':
                    case 'marPackage':
                    case 'marDisbilitie':
                    case 'marReview':
                    case 'marHelp':
                        return (<LayoutSetting />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                No Data Found
                            </div>
                        );
                }
            })()}
        </div>
    )
}
