import React, { useState, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';

export default function ComponentServicesList() {

    const [services, setServices] = useState([]);

    const [loading, setLoading] = useState(true);
    const [isAll, setIsAll] = useState(true);

    const loadData = () => {
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-4 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>Top Performing Customer</h3>
                </div>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                {
                    (services.length == 0 && !loading) && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                        <p className='text-center'>No Data Found.</p>
                    </div>
                }
                {
                    services.map((service) => {
                        return <div className='w-full flex my-2'>
                            <img src={service.category.iconURL} alt='Banner image' className='rounded w-[40px] h-[40px]' />
                            <div className='flex flex-col items-start justify-start ml-2'>
                                <h1 className='text-sm font-medium text-black'>{service.title}</h1>
                                <p className='text-xs font-medium text-gray-600'>{service.category.title}</p>
                            </div>
                            <div className='flex-grow'></div>
                            <div className='text-xs bg-yellow-200 text-yellow-600 h-[20px] px-2 rounded-full'>
                                <p><i className="las la-star text-sm"></i> {service.rateing.toFixed(2)}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
