import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import { FileText, FilePieChart } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';

import PanelTicket from '../panels/ticket/PanelTicket';
import PanelTicketLive from '../panels/ticket/PanelTicketLive';

export default function LayoutTicket() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'ticket':
                                return (<PanelTicket onToggle={toggleMenu} />);
                            case 'ticketLive':
                                return (<PanelTicketLive onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
