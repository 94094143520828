import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServicePack {
    lookupService = new APIServiceLookUp();

    async setPack(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}pack/setPack`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePack(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}pack/updatePack`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllPack(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}pack/getAllPack`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getPackOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}pack/getPackOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delPack(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}pack/delPack`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}